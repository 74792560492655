<template>
  <section class="call">
    <div class="call__wrapper">
      <p>
        Вы можете сами управлять своей судьбой и достичь результатов независимо от внешних обстоятельств.
      </p>
      <p>
        Прими участие в мастер-классе и получи <strong>пошаговый план Сергея Ким, который привел его к первому миллиону $.</strong>
      </p>
      <a href="https://krs.kassir.ru/obrazovanie/ot-gastarbaytera-do-milliardera" class="default__button call__link" target="_blank" rel="noopener noreferrer" onclick="ym(90660050,'reachGoal','clickkassir')">Купить билет</a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MkKimCall',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.call__wrapper {
  text-align: center;
}
.call__link {
  margin: 0 auto;
}
.call {
  padding-top: 30px;
  padding-bottom: 30px;
}
form {
  display: flex;
  flex-direction: column;
}
.call__wrapper {
  color: white;
  background-color: #262B2E;
  border-radius: 15px;
  padding: 15px;
}
@media (min-width: 1199.98px) {
  .call__wrapper {
    padding: 60px;
  }
}
</style>