<template>
  <section class="benefit">
    <h2 class="benefit__heading default__heading">
      Кому будет полезен Мастер-класс?
    </h2>
    <p>
      Мастер-класс будет полезен начинающим и действующим бизнесменам. 
      А также всем тем, кто хочет получить опыт руководителя миллиардной компании.
    </p>
    <p>У вас есть уникальная возможность получить практические советы о том, с чего начинать и как двигаться, чтобы достичь успеха.</p>
    <p>Только действующий и актуальный опыт. Никакой воды. Дорожная карта к первому
      миллиону долларов по которой мы шли.</p>
  </section>
</template>

<script>
export default {
  name: 'MkKimBenefit',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>